import { useEffect, useState } from 'react'

import { tr } from 'mmfintech-commons'
import { useCounterpartiesQry } from 'mmfintech-backend-api'

import { TableLoader, Tabs } from '../../../components'
import { CounterpartyTable } from './CounterpartyTable'
import { CounterpartyHeader } from './CounterpartyHeader'
import { CounterpartiesWrapper } from './CounterpartiesTab.styled'
import { ErrorDisplay, Pagination } from 'mmfintech-portal-commons'

import { CounterpartyStatusEnum } from 'mmfintech-commons-types'

export const CounterpartiesTab = () => {
  const config = [
    { name: 'active', title: tr('FRONTEND.COUNTERPARTY.TABS.ACTIVE', 'Active') },
    { name: 'archived', title: tr('FRONTEND.COUNTERPARTY.TABS.ARCHIVED', 'Archived') },
    { name: 'all', title: tr('FRONTEND.COUNTERPARTY.TABS.ALL', 'All') }
  ]

  const [activeTab, setActiveTab] = useState('active')

  const { filter, pagination, counterparties, counterpartiesError, counterpartiesFetching } = useCounterpartiesQry({
    state: CounterpartyStatusEnum.ACTIVE
  })

  const handleTabChanged = (tab: string) => {
    if (tab !== activeTab) {
      filter.set(
        'state',
        activeTab === 'active'
          ? CounterpartyStatusEnum.ACTIVE
          : activeTab === 'archived'
            ? CounterpartyStatusEnum.ARCHIVED
            : null
      )
      setActiveTab(tab)
    }
  }

  useEffect(() => {
    pagination.reload()
  }, [activeTab])

  return (
    <CounterpartiesWrapper>
      <CounterpartyHeader />

      <Tabs config={config} currentTab={activeTab} setTab={handleTabChanged} />

      {counterpartiesFetching ? (
        <TableLoader />
      ) : (
        <>
          <ErrorDisplay error={counterpartiesError} />

          <CounterpartyTable data={counterparties} />
          <Pagination {...pagination.register()} />
        </>
      )}
    </CounterpartiesWrapper>
  )
}
