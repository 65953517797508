import { useCallback, useEffect, useState } from 'react'

import { tr } from 'mmfintech-commons'

import { Button } from 'mmfintech-portal-commons'
import { TailSpin } from 'react-loader-spinner'
import { Document, Page } from 'react-pdf'
import { DocumentContainer, DocumentWrapper, PageFooter, PageWrapper } from './PreviewFile.styled'

import { PreviewFileProps } from './PreviewFile.types'

import PageChangeIcon from '../../images/icons/arrow-small-black.svg?react'
import ArrowDownIcon from '../../images/icons/arrow-down.svg?react'
import CloseIcon from '../../images/icons/close.svg?react'
import DefaultIcon from '../../images/icons/receipt-default.svg?react'

// const VIEWER_WIDTH = 300
// const MIN_VIEWER_HEIGHT = 385
// const SCALE = 2

// noinspection SpellCheckingInspection
const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/'
}

// noinspection JSUnusedGlobalSymbols
export const PreviewFile = (props: PreviewFileProps) => {
  const { file, error, loading, removeFile, downloadFile, stylingOptions } = props
  const { width = 300, height = 385, scale = width < 100 ? 1 : 2, showFooter = true } = stylingOptions || {}

  const [numPages, setNumPages] = useState<number>(0)
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [hasPdfError, setHasPdfError] = useState<boolean>(false)
  const [imageSource, setImageSource] = useState<string>(null)

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
    setPageNumber(1)
    setHasPdfError(false)
  }

  const changePage = (offset: number) => {
    setPageNumber(prevPageNumber => prevPageNumber + offset)
  }

  const previousPage = () => {
    if (pageNumber > 1) {
      changePage(-1)
    }
  }

  const nextPage = () => {
    if (pageNumber < numPages) {
      changePage(1)
    }
  }

  const handleMouseMove = useCallback((event: any) => {
    const wrapper = event.currentTarget
    const rect = wrapper.getBoundingClientRect()
    if (width < 100) {
      return
    }
    const xPercent = ((event.clientX - rect.left) / rect.width) * 100
    const yPercent = ((event.clientY - rect.top) / rect.height) * 100

    wrapper.style.setProperty('--x', `${xPercent}%`)
    wrapper.style.setProperty('--y', `${yPercent}%`)
  }, [])

  useEffect(() => {
    if (file) {
      setNumPages(0)
      setImageSource(file.fileUrl)
    }
  }, [file])

  return (
    <DocumentContainer $solidBorder>
      <DocumentWrapper $VIEWER_WIDTH={width}>
        {loading ? (
          <TailSpin />
        ) : error || !file ? (
          <div>{tr('FRONTEND.PREVIEW_FILE.NOT_LOAD_LABEL', 'Could not load the file')}</div>
        ) : file.type.includes('image') ? (
          <PageWrapper $MIN_VIEWER_HEIGHT={height} $SCALE={scale} onMouseMove={handleMouseMove}>
            {imageSource ? (
              <img
                src={imageSource}
                width={width}
                style={{ objectFit: 'contain' }}
                loading='lazy'
                onError={() => setImageSource(null)}
                alt=''
              />
            ) : (
              <DefaultIcon style={{ width: width, height: '100%' }} />
            )}
          </PageWrapper>
        ) : (
          <>
            {!hasPdfError ? (
              <Document
                file={file.fileUrl}
                options={options}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={() => setHasPdfError(true)}>
                <PageWrapper $MIN_VIEWER_HEIGHT={height} $SCALE={scale} onMouseMove={handleMouseMove}>
                  <Page pageNumber={pageNumber} width={width} _className='pdf-page' devicePixelRatio={scale} />
                </PageWrapper>
              </Document>
            ) : (
              <PageWrapper $MIN_VIEWER_HEIGHT={height} $SCALE={scale} onMouseMove={handleMouseMove}>
                <DefaultIcon style={{ width: width, height: '100%' }} />
              </PageWrapper>
            )}
          </>
        )}
      </DocumentWrapper>

      {showFooter && !loading && !!file && !file.type.includes('image') && (
        <PageFooter>
          <div className='buttons-container'>
            {typeof removeFile === 'function' ? (
              <Button
                text={tr('FRONTEND.PREVIEW_FILE.REMOVE_LABEL', 'Remove')}
                onClick={removeFile}
                color='round-secondary'
                icon={<CloseIcon height={15} width={15} />}
                iconPosition='left'
                className='action-button'
              />
            ) : null}
            {typeof downloadFile === 'function' ? (
              <Button
                text={tr('FRONTEND.PREVIEW_FILE.DOWNLOAD_LABEL', 'Download')}
                onClick={downloadFile}
                color='round-secondary'
                className='action-button'
                icon={<ArrowDownIcon />}
                iconPosition='left'
              />
            ) : null}
          </div>

          {numPages > 0 && (
            <div className='controls'>
              <PageChangeIcon onClick={previousPage} />
              <div>
                {tr('FRONTEND.PREVIEW_FILE.CURRENT_PAGE_NUMBER', '{{pageNumber}} of {{numPages}}', {
                  pageNumber,
                  numPages
                })}
              </div>
              <PageChangeIcon className='forward-icon' onClick={nextPage} />
            </div>
          )}
        </PageFooter>
      )}
    </DocumentContainer>
  )
}
