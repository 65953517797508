import { useHistory } from 'react-router-dom'

import { Button } from 'mmfintech-portal-commons'
import { AccountPaneWrapper, BalanceContainer, ButtonsContainer } from './AccountPane.styled'

import { tr, isValidArray, formatMoney } from 'mmfintech-commons'
import {
  isOwnerOrAdministrator,
  paths,
  selectCurrentUserRole,
  useAppSelector,
  useGetAggregatedBalanceQuery,
  useMyPermissionsQry
} from 'mmfintech-backend-api'

import DepositIcon from '../../../../images/icons/deposit.svg?react'
import TransferIcon from '../../../../images/icons/transfer.svg?react'
import ExchangeIcon from '../../../../images/icons/exchange.svg?react'

export const AccountPane = () => {
  const customerRole = useAppSelector(selectCurrentUserRole)

  const { balance } = useGetAggregatedBalanceQuery(
    {
      dateFrom: '',
      dateTo: ''
    },
    {
      selectFromResult: ({ data }) => ({
        balance: isValidArray(data) ? data[data.length - 1] : null
      })
    }
  )

  const { hasAnyWritePermission } = useMyPermissionsQry()

  const history = useHistory()

  const handleDepositClick = () => {
    history.push(paths.banking.deposit())
  }

  const handleTransferClick = () => {
    history.push(paths.banking.send())
  }

  const handleExchangeClick = () => {
    history.push(paths.banking.exchange())
  }

  return (
    <AccountPaneWrapper>
      <BalanceContainer>
        {balance && (
          <>
            <div className='label'>{tr('FRONTEND.DASHBOARD.TOTAL_ACCOUNT_VALUE', 'Total account value')}</div>
            <div data-test='balance'>{formatMoney(balance?.amount, balance?.currencyCode)}</div>
          </>
        )}
      </BalanceContainer>

      <ButtonsContainer>
        {isOwnerOrAdministrator(customerRole) && (
          <Button
            color='round-primary'
            className='sticky'
            data-test='button-deposit'
            icon={<DepositIcon className='fill' />}
            text={tr('FRONTEND.DASHBOARD.BUTTON_DEPOSIT', 'Deposit')}
            onClick={handleDepositClick}
          />
        )}
        {hasAnyWritePermission && (
          <>
            <Button
              color='round-primary'
              className='sticky'
              data-test='button-transfer'
              icon={<TransferIcon className='fill' />}
              text={tr('FRONTEND.DASHBOARD.BUTTON_SEND', 'Send money')}
              onClick={handleTransferClick}
            />
            <Button
              color='round-primary'
              className='sticky'
              data-test='button-transfer'
              icon={<ExchangeIcon className='fill' />}
              text={tr('FRONTEND.DASHBOARD.BUTTON_EXCHANGE', 'Exchange')}
              onClick={handleExchangeClick}
            />
          </>
        )}
      </ButtonsContainer>
    </AccountPaneWrapper>
  )
}
