import React from 'react'

import { DragAndDrop, FileInput } from 'mmfintech-portal-commons'

interface UploadedFile {
  fileUrl: string
  type: string
}

interface ExpenseDragAndDropComponent {
  file: UploadedFile
  onFileUpload: () => void
  onRemoveAll: () => void
  removeFile: () => void
  error: unknown
}

interface ExpenseDragAndDropProps {
  file: UploadedFile
  Component: React.FC<ExpenseDragAndDropComponent>
  onSelected?: (file: File) => void
  onError?: (error: any) => void
  onRemoveFile?: () => void
  error?: unknown
}

export const ExpenseDragAndDrop = (props: ExpenseDragAndDropProps) => {
  const { file, Component, onError, onSelected, onRemoveFile, error } = props

  const handleSelected = (list: File[] | FileList) => {
    if (list.length) {
      typeof onSelected === 'function' && onSelected(list[0])
    }
  }

  const handleError = (errors: any) => {
    typeof onError === 'function' && onError(errors)
  }

  const handleRemoveFile = () => {
    typeof onRemoveFile === 'function' && onRemoveFile()
  }

  return (
    <DragAndDrop showDropTarget={true} acceptType={['png', 'jpg', 'pdf']} onDrop={handleSelected} onError={handleError}>
      <FileInput
        maxNumber={1}
        // maxFileSize={certificateMaxFileSize}
        acceptType={['png', 'jpg', 'pdf']}
        onChange={handleSelected}
        onError={handleError}>
        {({ onFileUpload, onRemoveAll }) => (
          <Component
            file={file}
            onFileUpload={onFileUpload}
            onRemoveAll={onRemoveAll}
            removeFile={handleRemoveFile}
            error={error}
          />
        )}
      </FileInput>
    </DragAndDrop>
  )
}
