import styled from 'styled-components'

export const ReportSubheaderWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 1.4rem;
`

export const LeftReportSubheader = styled.div`
  display: flex;
  gap: 3.125rem;
  height: 60px;

  @media (max-width: 800px) {
    gap: 1.125rem;
    flex-direction: column;
    height: auto;
  }

  .submitter,
  .approver {
    border: 1px solid #4f4f4f;
    border-radius: 36px;
    padding: 6px 16px;
    width: fit-content;
  }

  & > .row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .label {
      font-size: 1.4rem;
      color: #808080;
    }

    .value {
      font-size: 1.6rem;
      color: white;
      padding-bottom: 6px;
    }
  }
`

export const ReportSummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.7rem;

  .label {
    display: block;
    text-align: right;
    font-size: 1.4rem;
    font-weight: 400;
    color: #808080;
    margin-bottom: 0.7rem;
  }
  .amount-value {
    display: block;
    font-size: 2rem;
    font-weight: 700;
    color: white;
    text-align: right;
  }
`
