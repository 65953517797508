import { useHistory } from 'react-router-dom'

import { paths } from 'mmfintech-backend-api'
import { isValidArray, tr } from 'mmfintech-commons'

import { DataTable } from 'mmfintech-portal-commons'
import { ReportStatusBadge } from '../components'
import { CounterpartyTableWrapper } from './CounterpartyTable.styled'

import { CounterpartyResponse } from 'mmfintech-commons-types'

interface CounterpartyTableProps {
  data: CounterpartyResponse[]
}

export const CounterpartyTable = ({ data }: CounterpartyTableProps) => {
  const history = useHistory()

  const handleCounterpartyRowClick = (counterparty: CounterpartyResponse) => {
    history.push(paths.expenseManagement.singleCounterparty(counterparty.id))
  }

  return (
    <CounterpartyTableWrapper>
      <DataTable.Table>
        <DataTable.Head>
          <DataTable.Row className='headRow'>
            <DataTable.HeadCell colSpan={2}>{tr('FRONTEND.COUNTERPARTY.COLUMN_NAME', 'Name')}</DataTable.HeadCell>
            <DataTable.HeadCell>
              {tr('FRONTEND.COUNTERPARTY.COLUMN_TOTAL_EXPENSES', 'Total Expenses')}
            </DataTable.HeadCell>
            <DataTable.HeadCell>
              {tr('FRONTEND.COUNTERPARTY.COLUMN_AWAITING_PAYMENT', 'Awaiting Payment')}
            </DataTable.HeadCell>
            <DataTable.HeadCell>{tr('FRONTEND.COUNTERPARTY.COLUMN_PAID', 'Paid')}</DataTable.HeadCell>
            <DataTable.HeadCell className='text-right'>
              {tr('FRONTEND.COUNTERPARTY.COLUMN_STATUS', 'Status')}
            </DataTable.HeadCell>
          </DataTable.Row>
        </DataTable.Head>

        <DataTable.Body className='main-tbody'>
          {isValidArray(data)
            ? data.map((counterparty: CounterpartyResponse) => {
                const { expenseMetrics } = counterparty
                return (
                  <DataTable.Row key={counterparty.id} onClick={() => handleCounterpartyRowClick(counterparty)}>
                    <DataTable.Cell caption={tr('FRONTEND.COUNTERPARTY.COLUMN_NAME', 'Name')} colSpan={2}>
                      {counterparty.name}
                    </DataTable.Cell>
                    <DataTable.Cell caption={tr('FRONTEND.COUNTERPARTY.COLUMN_TOTAL_EXPENSES', 'Total Expenses')}>
                      {expenseMetrics?.totalExpensesCount}
                    </DataTable.Cell>
                    <DataTable.Cell caption={tr('FRONTEND.COUNTERPARTY.COLUMN_AWAITING_PAYMENT', 'Awaiting Payment')}>
                      {expenseMetrics?.outstandingExpensesCount}
                    </DataTable.Cell>
                    <DataTable.Cell caption={tr('FRONTEND.COUNTERPARTY.COLUMN_PAID', 'Paid')}>
                      {expenseMetrics?.paidExpensesCount}
                    </DataTable.Cell>
                    <DataTable.Cell caption={tr('FRONTEND.COUNTERPARTY.COLUMN_STATUS', 'Status')}>
                      <ReportStatusBadge status={counterparty.state} />
                    </DataTable.Cell>
                  </DataTable.Row>
                )
              })
            : null}
        </DataTable.Body>
      </DataTable.Table>
    </CounterpartyTableWrapper>
  )
}
