import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import toast from 'react-hot-toast'

import { toastOptions } from '../../../components'
import { fixDateOnly, tr, useFormValues } from 'mmfintech-commons'
import { paths, useCreateReportMutation, useUpdateReportMutation } from 'mmfintech-backend-api'

import { GroupInputs } from '../../reports/expenses/styles/expenseModal.styled'
import { Button, ErrorDisplay, Input } from 'mmfintech-portal-commons'
import { ReportEditModalContainer, ReportEditModalHeader } from './ReportEditModal.styled'

import { ReportResponse } from 'mmfintech-commons-types'

import CalendarIcon from '../../../images/icons/filter-calendar.svg?react'

interface ReportEditModalProps {
  report?: ReportResponse
  visible: boolean
  onClose: () => void
}

export const ReportEditModal = ({ report, visible, onClose }: ReportEditModalProps) => {
  const history = useHistory()

  const [createReportRequest, { isLoading: createLoading, error: createError }] = useCreateReportMutation()
  const [updateReportRequest, { isLoading: updateLoading, error: updateError }] = useUpdateReportMutation()

  const formValues = useFormValues({
    name: { required: true, validation: 'safe-string' },
    description: { validation: 'safe-string' },
    fromDate: { required: true },
    toDate: { required: true }
  })

  const createReport = async () => {
    try {
      const res: any = await createReportRequest({
        ...formValues.prepare(),
        fromDate: fixDateOnly(formValues.getValue('fromDate')),
        toDate: fixDateOnly(formValues.getValue('toDate'))
      }).unwrap()
      toast.success(
        tr('FRONTEND.REPORTS.CREATE.CREATED_SUCCESSFULLY', 'Report created successfully'),
        toastOptions.success
      )
      onClose()
      history.push(paths.expenseManagement.report(res?.data.id))
    } catch {}
  }

  const updateReport = async () => {
    try {
      await updateReportRequest({
        ...formValues.prepare(),
        fromDate: fixDateOnly(formValues.getValue('fromDate')),
        toDate: fixDateOnly(formValues.getValue('toDate')),
        reportId: report?.id
      }).unwrap()
      toast.success(
        tr('FRONTEND.REPORTS.CREATE.EDITED_SUCCESSFULLY', 'Report edited successfully'),
        toastOptions.success
      )
      onClose()
    } catch {}
  }

  const handleSubmit = (e: React.BaseSyntheticEvent) => {
    e.preventDefault()
    if (formValues.areValid()) {
      if (report?.id) {
        void updateReport()
      } else {
        void createReport()
      }
    }
  }

  useEffect(() => {
    if (visible) {
      if (report) {
        formValues.setValue('name', report.name)
        formValues.setValue('description', report.description)
        formValues.setValue('fromDate', report.fromDate)
        formValues.setValue('toDate', report.toDate)
      }
    } else {
      formValues.reset()
    }
  }, [visible])

  useEffect(() => {
    if (createError) {
      formValues.handleErrors(createError)
    }
  }, [createError])

  useEffect(() => {
    if (updateError) {
      formValues.handleErrors(updateError)
    }
  }, [updateError])

  return (
    <ReportEditModalContainer>
      <ReportEditModalHeader>
        {report?.id
          ? tr('FRONTEND.REPORTS.CREATE.REPORT_DETAILS', 'Update report')
          : tr('FRONTEND.REPORTS.CREATE.CREATE_NEW_REPORT', 'Create new report')}
      </ReportEditModalHeader>

      <div>
        <Input
          type='text'
          label={tr('FRONTEND.REPORTS.CREATE.REPORT_NAME', 'Report name')}
          {...formValues.registerInput('name')}
        />

        <Input
          type='text'
          label={tr('FRONTEND.REPORTS.CREATE.DESCRIPTION', 'Description')}
          {...formValues.registerInput('description')}
        />

        <GroupInputs>
          <Input
            type='date'
            {...formValues.registerInput('fromDate')}
            label={tr('FRONTEND.REPORTS.CREATE.FROM', 'From')}
            icon={<CalendarIcon />}
            maxDate={formValues.getValue('toDate')}
            dateFormat='MM/dd/yyyy'
            parentClassName='calendar-input'
          />
          <Input
            type='date'
            {...formValues.registerInput('toDate')}
            label={tr('FRONTEND.REPORTS.CREATE.TO', 'To')}
            icon={<CalendarIcon />}
            minDate={formValues.getValue('fromDate')}
            dateFormat='MM/dd/yyyy'
            parentClassName='calendar-input'
          />
        </GroupInputs>
      </div>

      <ErrorDisplay error={createError || updateError} />

      <Button
        color='round-primary'
        data-test='create-edit-report'
        text={
          report?.id
            ? tr('FRONTEND.REPORTS.CREATE.SAVE', 'Save')
            : tr('FRONTEND.REPORTS.CREATE.CREATE_REPORT', 'Create report')
        }
        onClick={handleSubmit}
        loading={createLoading || updateLoading}
      />
    </ReportEditModalContainer>
  )
}
