import styled from 'styled-components'

export const ReportDeleteIconColumn = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  width: 2.4rem;
  svg {
    display: ${() => {
      if (window.matchMedia('(pointer: coarse)').matches) {
        // If device is using touch screen as main input then we need to display the delete icon always
        return 'flex'
      }
      return 'none'
    }};
  }
`

export const ReportTableWrapper = styled.div`
  .text-right {
    text-align: right;
  }

  tr {
    td {
      @media (max-width: 800px) {
        max-width: 100% !important;
        white-space: normal !important;
      }
    }
  }

  tr td:last-child {
    span {
      display: flex;
      justify-content: flex-end;
    }
  }

  .main-tbody {
    .image {
      @media (max-width: 800px) {
        display: none;
      }
    }

    tr {
      cursor: pointer;

      @media (max-width: 800px) {
        border: 1px solid #808080;
        border-radius: 10px;
        margin-top: 50px;

        td:last-child {
          border-bottom: none;
        }
      }
    }

    tr:hover {
      border-radius: 10px;
      box-shadow:
        20px 0 0 0 #111010,
        -20px 0 0 0 #111010;
      background-color: #111010;

      svg {
        display: flex;
      }

      @media (max-width: 800px) {
        box-shadow:
          0 0 0 0 #111010,
          0 0 0 0 #111010;
      }
    }
  }

  @media (max-width: 800px) {
    td:first-child {
      padding: 8px 0;
    }
  }

  tr:first-child:hover td:first-child {
    border-top-left-radius: 10px;
  }

  tr:first-child:hover td:last-child {
    border-top-right-radius: 10px;
  }

  tr:last-child:hover td:first-child {
    border-bottom-left-radius: 10px;
  }

  tr:last-child:hover td:last-child {
    border-bottom-right-radius: 10px;
  }
`

export const SubmitterBadge = styled.span`
  border-radius: 3.6rem;
  border: 1px solid #4f4f4f;
  padding: 8px 16px;
`
