import { useEffect, useState } from 'react'

import { fixDateOnly, tr, useDelayedExecute, useFilter, usePaginationQuery } from 'mmfintech-commons'
import {
  selectCurrentUserRole,
  useAppSelector,
  useGetMerchantQuery,
  useLazyGetReportsQuery
} from 'mmfintech-backend-api'

import { ReportFilters } from '../../reports/components/ReportFilters'
import { ReportsWrapper } from './ExpensesDashboard.styled'
import { TableLoader, Tabs } from '../../../components'
import { ErrorDisplay, Pagination } from 'mmfintech-portal-commons'
import { ReportsViewHeader, ReportsTable } from '../../reports'

import { CustomerRole, CustomerRoleEnum, ReportStateEnum } from 'mmfintech-commons-types'

export const ExpensesDashboard = () => {
  const { isFetching: merchantFetching } = useGetMerchantQuery()
  const customerRole = useAppSelector(selectCurrentUserRole)

  const config = tabsConfig(customerRole)

  const [activeTab, setActiveTab] = useState(config?.[0]?.name)

  const [fetchReports, { data, error: reportsError, isFetching: reportsFetching }] = useLazyGetReportsQuery()

  const filter = useFilter(
    {},
    ['search'],
    ['fromDate', 'toDate'],
    () => {
      delayedReload.execute()
    },
    true
  )

  const delayedReload = useDelayedExecute({
    name: 'delayedExpensesReload',
    onExecute: () => pagination.reload({ page: 0 })
  })

  const pagination = usePaginationQuery({
    rowsPerPage: 7,
    reload: async (params: any, onSuccess: (response: any) => void) => {
      try {
        const res = await fetchReports({
          ...params,
          ...filter.prepare(),
          fromDate: fixDateOnly(filter.get('from')),
          toDate: fixDateOnly(filter.get('to')),
          owner: activeTab === TabsEnum.YOUR_REPORTS,
          states: getStates(activeTab),
          hideDraftReports: activeTab === TabsEnum.ALL_FINANCE,
          deleted: false
        }).unwrap()
        onSuccess(res)
      } catch {}
    }
  })

  const handleTabChange = (tab: TabsEnum) => {
    if (tab !== activeTab) {
      setActiveTab(tab)
    }
  }

  useEffect(() => {
    pagination.reload()
  }, [activeTab])

  return (
    <ReportsWrapper>
      <ReportsViewHeader pagination={pagination} />
      <ReportFilters filter={filter} />

      <Tabs config={config} currentTab={activeTab} setTab={handleTabChange} />

      {merchantFetching || reportsFetching ? (
        <TableLoader />
      ) : (
        <>
          <ErrorDisplay error={reportsError} />

          <ReportsTable
            data={data?.content || []}
            reloadPagination={() => pagination.reload()}
            isApproverTable={[
              TabsEnum.AWAITING_APPROVAL,
              TabsEnum.AWAITING_FINANCE_APPROVAL,
              TabsEnum.AWAITING_PAYMENT,
              TabsEnum.PAID,
              TabsEnum.ALL_FINANCE
            ].includes(activeTab)}
          />

          <Pagination {...pagination.register()} />
        </>
      )}
    </ReportsWrapper>
  )
}

enum TabsEnum {
  ALL_REPORTS = 'all',
  PENDING = 'pending',
  DRAFT = 'draft',
  AWAITING_APPROVAL = 'awaiting-approval',
  YOUR_REPORTS = 'your-reports',
  ALL_FINANCE = 'all-finance',
  AWAITING_FINANCE_APPROVAL = 'awaiting-finance-approval',
  AWAITING_PAYMENT = 'awaiting-payment',
  PAID = 'paid'
}

const tabsConfig = (role: CustomerRole) => {
  switch (role) {
    case CustomerRoleEnum.SUBMITTER:
      return [
        { name: TabsEnum.ALL_REPORTS, title: tr('FRONTEND.REPORTS.TABS.ALL_REPORTS', 'All reports') },
        { name: TabsEnum.PENDING, title: tr('FRONTEND.REPORTS.TABS.PENDING_REPORTS', 'Pending reports') },
        { name: TabsEnum.DRAFT, title: tr('FRONTEND.REPORTS.TABS.DRAFT_REPORTS', 'Draft reports') }
      ]
    case CustomerRoleEnum.APPROVER:
      return [
        {
          name: TabsEnum.AWAITING_APPROVAL,
          title: tr('FRONTEND.REPORTS.TABS.AWAITING_YOUR_APPROVAL', 'Awaiting your approval')
        },
        { name: TabsEnum.YOUR_REPORTS, title: tr('FRONTEND.REPORTS.TABS.YOUR_REPORTS', 'Your reports') }
      ]
    case CustomerRoleEnum.OWNER:
    case CustomerRoleEnum.ADMINISTRATOR:
      return [
        { name: TabsEnum.ALL_FINANCE, title: tr('FRONTEND.REPORTS.TABS.ALL_REPORTS', 'All reports') },
        {
          name: TabsEnum.AWAITING_FINANCE_APPROVAL,
          title: tr('FRONTEND.REPORTS.TABS.AWAITING_YOUR_APPROVAL', 'Awaiting your approval')
        },
        {
          name: TabsEnum.AWAITING_PAYMENT,
          title: tr('FRONTEND.REPORTS.TABS.AWAITING_PAYMENT', 'Awaiting payment')
        },
        { name: TabsEnum.PAID, title: tr('FRONTEND.REPORTS.TABS.CONCLUDED', 'Concluded') }
      ]
    default:
      return []
  }
}

const getStates = (tab: string) => {
  switch (tab) {
    case TabsEnum.AWAITING_APPROVAL:
      return [ReportStateEnum.WAITING_APPROVAL]
    case TabsEnum.AWAITING_FINANCE_APPROVAL:
      return [ReportStateEnum.WAITING_FINANCE_APPROVAL]
    case TabsEnum.AWAITING_PAYMENT:
      return [ReportStateEnum.APPROVED]
    case TabsEnum.PAID:
      return [ReportStateEnum.COMPLETELY_PAID, ReportStateEnum.PARTIALLY_PAID, ReportStateEnum.UNPAID]
    case TabsEnum.DRAFT:
      return [ReportStateEnum.NEW]
    case TabsEnum.PENDING:
      return [ReportStateEnum.WAITING_APPROVAL, ReportStateEnum.WAITING_FINANCE_APPROVAL, ReportStateEnum.APPROVED]
    default:
      return []
  }
}
