import { ChartPane } from './Chart'
import { FxRateButton } from './FxRates'
import { ExchangePaneContent, ExchangePaneWrapper, GraphPaneWrapper } from './GraphPane.styled'

import { isNotEmptyString } from 'mmfintech-commons'
import { selectMerchantCurrency, useAppSelector, useExchangePairsQry, useGetMerchantQuery } from 'mmfintech-backend-api'

export const GraphPane = () => {
  const { data: merchant } = useGetMerchantQuery()
  const merchantCurrency = useAppSelector(selectMerchantCurrency)

  const { updatedFxRates } = useExchangePairsQry({
    fixedRates:
      merchant?.legalEntity === 'ST_POLAND' && isNotEmptyString(merchantCurrency)
        ? [`GBP/${merchantCurrency}`, `USD/${merchantCurrency}`]
        : null
  })

  return (
    <GraphPaneWrapper>
      <ChartPane />
      <ExchangePaneWrapper>
        <ExchangePaneContent>
          {Array.isArray(updatedFxRates) &&
            updatedFxRates
              ?.filter((_node, index) => index < 6)
              .map((node, index) => <FxRateButton key={index} node={node} />)}
        </ExchangePaneContent>
      </ExchangePaneWrapper>
    </GraphPaneWrapper>
  )
}
