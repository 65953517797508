import { useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import styled from 'styled-components'
import { paths } from 'mmfintech-backend-api'

import ExpensesDashboard from '../dashboard/ExpensesDashboard'

import { ReportsWrapper } from '../dashboard/ExpensesDashboard/ExpensesDashboard.styled'
import { NavigationSwitch } from './NavigationSwitch'
import { CounterpartiesTab } from './counterpartiesTab'

export const ExpenseManagement = () => {
  const [selectedView, setSelectedView] = useState<string>('reports')

  return (
    <ExpenseManagementWrapper>
      <div>
        <NavigationSwitch selected={selectedView} setSelected={setSelectedView} />

        <Switch>
          <Route path={paths.expenseManagement.reports()} exact component={ExpensesDashboard} />
          <Route path={paths.expenseManagement.counterParty()} exact component={CounterpartiesTab} />
          <Redirect to={paths.expenseManagement.reports()} />
        </Switch>
      </div>
    </ExpenseManagementWrapper>
  )
}

const ExpenseManagementWrapper = styled(ReportsWrapper)`
  padding: 0;
`
