import styled from 'styled-components'
import { CounterpartyStatusEnum, ReportStateEnum } from 'mmfintech-commons-types'

const getStatusColor = (status: string) => {
  switch (status) {
    // Counterparties status colors
    case CounterpartyStatusEnum.ACTIVE:
      return '#00B451'
    case CounterpartyStatusEnum.ARCHIVED:
      return '#AFAFAF'
    // End Counterparties status labels

    case ReportStateEnum.NEW:
      return '#AFAFAF'

    case ReportStateEnum.WAITING_APPROVAL:
    case ReportStateEnum.WAITING_FINANCE_APPROVAL:
    case ReportStateEnum.PARTIALLY_PAID:
      return '#FFCE32'

    case ReportStateEnum.REJECTED:
    case ReportStateEnum.UNPAID:
      return '#E8112D'

    case ReportStateEnum.APPROVED:
    case ReportStateEnum.EDIT:
      return '#00A49F'

    case ReportStateEnum.COMPLETELY_PAID:
      return '#00B451'

    default:
      return '#000000'
  }
}

export const ReportStatusBadgeContainer = styled.div<{
  $status: string
}>`
  display: inline-flex;
  padding: 0.6rem 1.2rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  border-radius: 3.6rem;
  border: 1px solid ${props => getStatusColor(props.$status)};
  color: ${props => getStatusColor(props.$status)}; // Set the text color
`
