import toast from 'react-hot-toast'

import { tr } from 'mmfintech-commons'
import { useDeleteReportMutation } from 'mmfintech-backend-api'

import { ExpenseModalHeader } from '../../expenses/styles/expenseModal.styled'
import { Button, ErrorDisplay } from 'mmfintech-portal-commons'
import { ReportModalInner, ReportModalWrapper } from './modals.styled'

import { ReportResponse } from 'mmfintech-commons-types'
import React from 'react'

interface DeleteReportProps {
  report: ReportResponse
  onClose: () => void
}

export const DeleteReportModal = ({ report, onClose }: DeleteReportProps) => {
  const [deleteReport, { error, isLoading }] = useDeleteReportMutation()

  const onDeleteReport = async (e: React.MouseEvent) => {
    e.stopPropagation()

    try {
      await deleteReport({ reportId: report.id }).unwrap()
      toast.success(tr('FRONTEND.EXPENSE_MODAL.REPORT_DELETE_SUCCESS', 'Report deleted successfully'))
      onClose()
    } catch {}
  }

  return (
    <ReportModalWrapper onMouseDown={e => e.stopPropagation()}>
      <ExpenseModalHeader>{tr('FRONTEND.EXPENSE_MODAL.DELETE_REPORT_LABEL', 'Delete Report')}</ExpenseModalHeader>
      <ReportModalInner>
        <div className='confirmation'>
          {tr('FRONTEND.EXPENSE_MODAL.SECURE_MASSAGE_LABEL', 'Are you sure you want to delete report')}:{' '}
          <span>{report.name}</span>
        </div>
      </ReportModalInner>
      <ErrorDisplay error={error} />
      <Button
        color='round-primary'
        text={tr('FRONTEND.BUTTONS.DELETE', 'Delete')}
        onClick={onDeleteReport}
        data-test='delete-report-button'
        loading={isLoading}
      />
    </ReportModalWrapper>
  )
}
