import styled from 'styled-components'

interface NavigationContainerProps {
  navigationCount: number
}

interface NavigationProps {
  isActive?: boolean
  activeIndex?: number
  navigationCount?: number
}

export const NavigationContainer = styled.div<NavigationContainerProps>`
  display: grid;
  grid-template-columns: ${props => `repeat(${props.navigationCount}, 1fr)`};
  height: 41px;
  background: #000;
  border-radius: 3.6rem;
  position: relative;
  width: max-content; // Set the width as needed
  margin: 72px 3rem 0;

  @media (max-width: 960px) {
    width: auto;
  }
`

// Styled component for the buttons
export const NavigationButton = styled.button<NavigationProps>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 9px 24px;

  border-radius: inherit;
  cursor: pointer;
  color: ${props => (props.isActive ? '#000' : '#fff')};
  background: transparent;
  transition: background-color 0.3s;
  border: none; // Remove button border
  outline: none; // Remove focus outline
  z-index: 1;
  -webkit-tap-highlight-color: transparent;
  white-space: nowrap;

  span {
    font-size: 14px;
    font-weight: 500;
  }
`

// Styled component for the highlighter
export const Highlighter = styled.div<NavigationProps>`
  position: absolute;
  left: ${props =>
    (props.activeIndex || 0) * (100 / props.navigationCount)}%; // Calculate left position based on the active element
  transition: left 0.3s ease; // Smooth transition for the sliding effect
  background: #fff;
  border-radius: 3.6rem;
  width: ${props => 100 / props.navigationCount - 1.5}%;
  margin: 3px;
  box-sizing: border-box;
  height: calc(100% - 6px);
`
