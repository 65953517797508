import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { tr } from 'mmfintech-commons'
import { paths } from 'mmfintech-backend-api'

import { Highlighter, NavigationButton, NavigationContainer } from './NavigationSwitch.styled'

interface ConfigItem {
  path: string
  name: string
  displayName: string
}

interface NavigationSwitchProps {
  selected: string
  setSelected: (arg: unknown) => void
}

export const NavigationSwitch = ({ selected, setSelected }: NavigationSwitchProps) => {
  const { pathname } = useLocation()

  const history = useHistory()

  const navigationConfig: ConfigItem[] = [
    {
      path: paths.expenseManagement.reports(),
      name: 'reports',
      displayName: tr('FRONTEND.HEADER.REPORTS', 'Reports')
    },
    {
      path: paths.expenseManagement.counterParty(),
      name: 'counterparty',
      displayName: tr('FRONTEND.HEADER.COUNTERPARTY', 'Counterparties')
    }
    // {
    //   path: paths.banking.expenses(),
    //   name: 'test',
    //   displayName: tr('FRONTEND.EXPENSES.UPLOAD_FILE.DETAILS_VIEW', 'Analytics')
    // }
  ]

  useEffect(() => {
    const endPath = pathname.split('/')[2]
    if (endPath) {
      setSelected(endPath)
    }
  }, [pathname])

  const handleViewChange = (name: string) => setSelected(name)

  const handleTabChanged = (name: string, path: string) => {
    handleViewChange(name)
    history.push(path)
  }

  return (
    <NavigationContainer navigationCount={navigationConfig?.length}>
      <Highlighter
        activeIndex={navigationConfig.map(e => e.name).indexOf(selected)}
        navigationCount={navigationConfig?.length}
      />
      {navigationConfig.map(({ path, name, displayName }) => (
        <NavigationButton key={name} isActive={selected === name} onClick={() => handleTabChanged(name, path)}>
          <span>{displayName}</span>
        </NavigationButton>
      ))}
    </NavigationContainer>
  )
}
