import { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import cn from 'classnames'

import { CustomizeTableModal } from './CustomizeTableModal'
import { TransactionsContainer } from './TransactionsTable.styled'
import { Button, DataTable, NoElementsMessage } from 'mmfintech-portal-commons'

import settings from '../../../settings'
import {
  GlobalContext,
  tr,
  isValidArray,
  fetchLastChunk,
  formatDate,
  formatMoney,
  isValidString
} from 'mmfintech-commons'
import {
  extractCounterpartyName,
  formatTransactionDescription,
  getTransactionStatusName,
  paths,
  useCurrencies,
  useTransactionColumns
} from 'mmfintech-backend-api'

import { TransactionViewPortal } from 'mmfintech-commons-types'

import CustomizeIcon from '../../../images/icons/customize.svg?react'

type TransactionsTableProps = {
  transactions: TransactionViewPortal[]
  origin?: string
}

export const TransactionsTable = ({ transactions, origin }: TransactionsTableProps) => {
  const { modalHide, modalShow } = useContext(GlobalContext)

  const history = useHistory()

  const { getCurrencyPrecision } = useCurrencies()

  const columns = useTransactionColumns({
    cookieDomain: `${window.location.hostname}.${settings.cookieDomain}`,
    enableCustomerEmail: false,
    enableFailReason: true,
    defaultVisible: undefined
  })

  const handleCustomizeClick = () => {
    modalShow({
      options: { closeOnClickOutside: false, maxWidth: 450, size: 'medium' },
      content: <CustomizeTableModal columns={columns} onClose={modalHide} />
    })
  }

  const handleDetailsClick = (transactionId: number): void => {
    history.push(paths.banking.transactions.details(transactionId), { origin })
  }

  return (
    <TransactionsContainer>
      <div className='customize-columns-button'>
        <Button
          type='button'
          color='round-secondary'
          onClick={handleCustomizeClick}
          text={tr('FRONTEND.TRANSACTIONS.COLUMNS.CUSTOMIZE_BUTTON', 'Customize')}
          data-test='customize-columns'
          icon={<CustomizeIcon />}
        />
      </div>
      {isValidArray(transactions) ? (
        <DataTable.Table scale2='1200px'>
          <DataTable.Head>
            <DataTable.Row>
              {columns.isVisible('id') && (
                <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.ID', 'ID')}</DataTable.HeadCell>
              )}
              {columns.isVisible('foreignTransactionId') && (
                <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.FOREIGN_ID', 'Foreign ID')}</DataTable.HeadCell>
              )}
              {columns.isVisible('account') && (
                <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.ACCOUNT', 'Account')}</DataTable.HeadCell>
              )}
              <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.DATE', 'Date')}</DataTable.HeadCell>
              {columns.isVisible('type') && (
                <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.TRANSACTION_TYPE', 'Type')}</DataTable.HeadCell>
              )}
              {columns.isVisible('method') && (
                <DataTable.HeadCell>
                  {tr('FRONTEND.TRANSACTIONS.COLUMNS.PAYMENT_METHOD', 'Payment Method')}
                </DataTable.HeadCell>
              )}
              {columns.isVisible('description') && (
                <DataTable.HeadCell>
                  {tr('FRONTEND.TRANSACTIONS.COLUMNS.DESCRIPTION', 'Description')}
                </DataTable.HeadCell>
              )}
              {columns.isVisible('customer') && (
                <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.CUSTOMER', 'Counterparty')}</DataTable.HeadCell>
              )}
              {columns.isVisible('customerEmail') && (
                <DataTable.HeadCell>
                  {tr('FRONTEND.TRANSACTIONS.COLUMNS.CUSTOMER_EMAIL', 'Customer email')}
                </DataTable.HeadCell>
              )}
              {columns.isVisible('reference') && (
                <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.REFERENCE', 'Reference')}</DataTable.HeadCell>
              )}
              <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.AMOUNT', 'Amount')}</DataTable.HeadCell>
              {columns.isVisible('status') && (
                <DataTable.HeadCell className='thin'>
                  {tr('FRONTEND.TRANSACTIONS.COLUMNS.STATUS', 'Status')}
                </DataTable.HeadCell>
              )}
              {columns.isVisible('failReason') && (
                <DataTable.HeadCell className='thin'>
                  {tr('FRONTEND.TRANSACTIONS.COLUMNS.STATUS_DESCRIPTION', 'Fail reason')}
                </DataTable.HeadCell>
              )}
              <DataTable.HeadCell className='buttons'>
                <Button
                  type='button'
                  color='round-secondary'
                  onClick={handleCustomizeClick}
                  text={tr('FRONTEND.TRANSACTIONS.COLUMNS.CUSTOMIZE_BUTTON', 'Customize')}
                  data-test='customize-columns'
                  icon={<CustomizeIcon />}
                />
              </DataTable.HeadCell>
            </DataTable.Row>
          </DataTable.Head>

          <DataTable.Body>
            {transactions.map((data: TransactionViewPortal, index: number) => {
              const {
                id,
                amount: transactionAmount,
                currency: transactionCurrency,
                foreignTransactionId,
                status,
                reqTime,
                direction,
                reference,
                accountName,
                paymentMethod,
                trnTypeLocalizationKey,
                statusCodeLocalizationKey,
                statusCodeDefaultDescription,
                checkoutDetails,
                cardPaymentDetails
              } = data
              const { customerEmail } = checkoutDetails || {}
              const { transactionAmount: cardPaymentAmount, transactionCurrency: cardPaymentCurrency } =
                cardPaymentDetails || {}

              const amount = cardPaymentAmount ? cardPaymentAmount : transactionAmount
              const currency = cardPaymentAmount ? cardPaymentCurrency : transactionCurrency

              return (
                <DataTable.Row key={index} data-test='table-row'>
                  {columns.isVisible('id') && (
                    <DataTable.Cell caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.ID', 'ID')}>{id}</DataTable.Cell>
                  )}
                  {columns.isVisible('foreignTransactionId') && (
                    <DataTable.Cell caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.FOREIGN_ID', 'Foreign ID')}>
                      {foreignTransactionId}
                    </DataTable.Cell>
                  )}
                  {columns.isVisible('account') && (
                    <DataTable.Cell caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.ACCOUNT', 'Account')}>
                      {accountName}
                    </DataTable.Cell>
                  )}
                  <DataTable.Cell caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.DATE', 'Date')} className='no-wrap'>
                    {formatDate(reqTime)}
                  </DataTable.Cell>
                  {columns.isVisible('type') && (
                    <DataTable.Cell caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.TRANSACTION_TYPE', 'Type')}>
                      {tr(trnTypeLocalizationKey, fetchLastChunk(trnTypeLocalizationKey))}
                    </DataTable.Cell>
                  )}
                  {columns.isVisible('method') && (
                    <DataTable.Cell caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.PAYMENT_METHOD', 'Payment Method')}>
                      {tr(`METADATA.PAYMENT_OPTIONS.${paymentMethod}`, paymentMethod)}
                    </DataTable.Cell>
                  )}
                  {columns.isVisible('description') && (
                    <DataTable.Cell caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.DESCRIPTION', 'Description')}>
                      {formatTransactionDescription(data)}
                    </DataTable.Cell>
                  )}
                  {columns.isVisible('customer') && (
                    <DataTable.Cell caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.CUSTOMER', 'Counterparty')}>
                      {extractCounterpartyName(data)}
                    </DataTable.Cell>
                  )}
                  {columns.isVisible('customerEmail') && (
                    <DataTable.Cell caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.CUSTOMER_EMAIL', 'Customer email')}>
                      {customerEmail}
                    </DataTable.Cell>
                  )}
                  {columns.isVisible('reference') && (
                    <DataTable.Cell caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.REFERENCE', 'Reference')}>
                      {reference}
                    </DataTable.Cell>
                  )}
                  <DataTable.Cell caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.AMOUNT', 'Amount')}>
                    {
                      <span className='amount'>
                        {formatMoney(
                          amount * (direction === 'WITHDRAW' ? -1 : 1),
                          currency,
                          getCurrencyPrecision(currency)
                        )}
                      </span>
                    }
                  </DataTable.Cell>
                  {columns.isVisible('status') && (
                    <DataTable.Cell
                      caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.STATUS', 'Status')}
                      className='status-cell'>
                      <span className={cn('status', 'status-' + status.toLowerCase())}>
                        {getTransactionStatusName(status)}
                      </span>
                    </DataTable.Cell>
                  )}
                  {columns.isVisible('failReason') && (
                    <DataTable.Cell caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.STATUS_DESCRIPTION', 'Fail reason')}>
                      {isValidString(statusCodeLocalizationKey)
                        ? tr(statusCodeLocalizationKey, statusCodeDefaultDescription)
                        : statusCodeDefaultDescription}
                    </DataTable.Cell>
                  )}
                  <DataTable.Cell className='preview-cell no-border'>
                    <Button
                      type='button'
                      color='round-secondary'
                      data-test='details-button'
                      text={tr('FRONTEND.TRANSACTIONS.DETAILS_BUTTON', 'Details')}
                      onClick={() => handleDetailsClick(id)}
                    />
                  </DataTable.Cell>
                </DataTable.Row>
              )
            })}
          </DataTable.Body>
        </DataTable.Table>
      ) : (
        <NoElementsMessage
          message={tr(
            'FRONTEND.BANKING.TRANSACTIONS.NO_TRANSACTIONS',
            'No transactions for the selected period or search criteria'
          )}
        />
      )}
    </TransactionsContainer>
  )
}
