import { useState } from 'react'

import { tr } from 'mmfintech-commons'

import { Button } from 'mmfintech-portal-commons'
import { ModalDialog } from '../../../components'
import { CounterpartyEditModal } from '../components'
import { HeaderContainer, VerticallyCentered } from '../../reports/ReportsTableHeaderStyled'

import AddIcon from '../../../images/icons/deposit.svg?react'

export const CounterpartyHeader = () => {
  const [modalVisible, setModalVisible] = useState<boolean>(false)

  const hideModal = () => setModalVisible(false)
  const showModal = () => setModalVisible(true)

  return (
    <HeaderContainer>
      <div className='left-wrapper'></div>
      <div className='right-wrapper-counterparty'>
        <VerticallyCentered>
          <Button
            color='round-primary'
            className='sticky'
            text={tr('FRONTEND.EXPENSE_MANAGEMENT.CREATE_NEW', 'Create new')}
            icon={<AddIcon />}
            data-test='button-add-counterparty'
            onClick={showModal}
          />
          <ModalDialog
            options={{ size: 'lg', closeOnEscape: false, closeOnClickOutside: false }}
            visible={modalVisible}
            content={<CounterpartyEditModal visible={modalVisible} onClose={hideModal} />}
            onClose={hideModal}
          />
        </VerticallyCentered>
      </div>
    </HeaderContainer>
  )
}
