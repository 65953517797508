import { useHistory } from 'react-router-dom'

import { Button } from 'mmfintech-portal-commons'
import { AccountOverview } from '../../account/AccountsOverview'
import { TransactionsTable } from '../../transactions/TransactionsTable'
import { SpendingLimitBarUser } from '../../../components'
import { DashboardContainer, StaffDashboardWrapper } from '../Dashboard.styled'

import { tr } from 'mmfintech-commons'
import { paths, useMyPermissionsQry, useTransactionsFilterQry } from 'mmfintech-backend-api'

import DepositIcon from '../../../images/icons/deposit.svg?react'
import TransferIcon from '../../../images/icons/transfer.svg?react'

export const StaffDashboard = () => {
  const history = useHistory()
  const { hasAnyWritePermission } = useMyPermissionsQry()

  const filter = useTransactionsFilterQry({})

  const handleDepositClick = () => {
    history.push(paths.banking.deposit())
  }

  const handleTransferClick = () => {
    history.push(paths.banking.send())
  }

  return (
    <DashboardContainer>
      <StaffDashboardWrapper>
        {hasAnyWritePermission && (
          <div className='dashboard-nav'>
            <SpendingLimitBarUser />

            <div>
              <Button
                color='round-primary'
                className='sticky mr-1'
                data-test='button-deposit'
                icon={<DepositIcon className='fill' />}
                text={tr('FRONTEND.DASHBOARD.BUTTON_DEPOSIT', 'Deposit')}
                onClick={handleDepositClick}
              />
              <Button
                color='round-primary'
                className='sticky'
                data-test='button-transfer'
                icon={<TransferIcon className='fill' />}
                text={tr('FRONTEND.DASHBOARD.BUTTON_SEND', 'Send Money')}
                onClick={handleTransferClick}
              />
            </div>
          </div>
        )}

        <AccountOverview />
        <TransactionsTable transactions={filter.transactions} />
      </StaffDashboardWrapper>
    </DashboardContainer>
  )
}
