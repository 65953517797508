import React, { useState } from 'react'

import { ModalDialog } from '../../../components'
import { DeleteReportModal } from '../report/modals/DeleteReportModal'
import { ReportDeleteIconColumn } from '../ReportsTable.styled'

import { ReportResponse } from 'mmfintech-commons-types'

import DeleteIcon from '../../../images/expenses/trash-icon.svg?react'

interface DeleteReportButtonProps {
  report: ReportResponse
  reload: () => void
}

const DeleteReportButton = ({ report, reload }: DeleteReportButtonProps) => {
  const [visible, setVisible] = useState<boolean>(false)

  const onOpenModal = (e: React.MouseEvent) => {
    e.stopPropagation()
    setVisible(true)
  }
  const onCloseModal = () => {
    reload()
    setVisible(false)
  }

  return (
    <>
      <ReportDeleteIconColumn onMouseDown={e => e.stopPropagation()}>
        <DeleteIcon data-test='open-delete-report-modal' onClick={onOpenModal} />
      </ReportDeleteIconColumn>

      <ModalDialog
        visible={visible}
        onClose={onCloseModal}
        options={{ size: 'lg', closeOnEscape: false, closeOnClickOutside: true }}
        content={<DeleteReportModal report={report} onClose={onCloseModal} />}
      />
    </>
  )
}

export default DeleteReportButton
