import { Preloader } from 'mmfintech-portal-commons'
import { AccountOverviewRow } from './AccountsOverviewRow'
import { AccountOverviewListWrapper } from './AccountsOverview.styled'

import { useMerchantAccounts, useMyPermissionsQry } from 'mmfintech-backend-api'

export const AccountOverview = () => {
  const { permissions, permissionsFetching } = useMyPermissionsQry()
  const { activeAccounts, accountsFetching } = useMerchantAccounts()

  if (accountsFetching || permissionsFetching) {
    return <Preloader />
  }

  const accounts = activeAccounts?.map((a: any) => {
    const permission = permissions?.find((perm: any) => a?.id === perm?.accountId)
    return { ...a, permission: permission?.accessLevel }
  })

  return (
    <AccountOverviewListWrapper>
      {accounts?.map(account => <AccountOverviewRow account={account} key={account.id} />)}
    </AccountOverviewListWrapper>
  )
}
