import { FormEvent, useEffect } from 'react'

import toast from 'react-hot-toast'

import { isValidObject, tr, useFormValues } from 'mmfintech-commons'
import { useCreateCounterpartyMutation, useUpdateCounterpartyMutation } from 'mmfintech-backend-api'

import { Button, Input } from 'mmfintech-portal-commons'
import { ExpenseModalHeader } from '../../reports/expenses/styles/expenseModal.styled'
import { CounterpartyModalWrapper } from './CounterpartyEditModal.styled'

import { CounterpartyResponse } from 'mmfintech-commons-types'

interface CounterpartyCreateModalProps {
  counterparty?: CounterpartyResponse
  visible?: boolean
  onClose: () => void
}

export const CounterpartyEditModal = ({ counterparty, visible, onClose }: CounterpartyCreateModalProps) => {
  const formValues = useFormValues({
    name: { required: true, validation: 'safe-string' }
  })

  const [createCounterpartyRequest, { isLoading: createLoading, reset: resetCreate }] = useCreateCounterpartyMutation()
  const [updateCounterpartyRequest, { isLoading: updateLoading, reset: resetUpdate }] = useUpdateCounterpartyMutation()

  const handleReset = () => {
    resetCreate()
    resetUpdate()
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    if (formValues.areValid()) {
      try {
        counterparty
          ? await updateCounterpartyRequest({ ...formValues.prepare(), counterpartyId: counterparty?.id }).unwrap()
          : await createCounterpartyRequest(formValues.prepare()).unwrap()
        onClose()
      } catch (error) {
        toast.error(error?.cause?.[0]?.cause || error)
      }
    }
  }

  useEffect(() => {
    if (visible) {
      if (counterparty) {
        formValues.setValue('name', counterparty.name)
      }
    } else {
      handleReset()
    }
  }, [visible])

  return (
    <CounterpartyModalWrapper>
      <ExpenseModalHeader>
        {isValidObject(counterparty)
          ? tr('FRONTEND.COUNTERPARTY.ACTION.UPDATE', 'Update')
          : tr('FRONTEND.COUNTERPARTY.ACTION.NEW', 'New')}{' '}
        {tr('FRONTEND.EXPENSE_MANAGEMENT.TITLE.COUNTERPARTY', 'Counterparty')}
      </ExpenseModalHeader>

      <form onSubmit={handleSubmit}>
        <Input
          type='text'
          label={tr('FRONTEND.COUNTERPARTY.COLUMN_NAME', 'Name')}
          {...formValues.registerInput('name')}
        />

        <Button
          type='submit'
          color='round-primary'
          data-test='create-update-counterpary'
          text={
            isValidObject(counterparty)
              ? tr('FRONTEND.COUNTERPARTY.ACTION.UPDATE', 'Update')
              : tr('FRONTEND.COUNTERPARTY.ACTION.CREATE', 'Create')
          }
          loading={createLoading || updateLoading}
        />
      </form>
    </CounterpartyModalWrapper>
  )
}
