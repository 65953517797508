// noinspection JSCheckFunctionSignatures

import React from 'react'

import Login from './views/auth/Login'
import Outlet from './views/Outlet'
import Dashboard from './views/dashboard'
import ExpenseDashboard from './views/dashboard/ExpensesDashboard'
import ExpenseManagement from './views/expenseManagement'

import { configuration, paths } from 'mmfintech-backend-api'

const Signup = React.lazy(() => import('./views/auth/Signup'))

const Settings = React.lazy(() => import('./views/settings'))

const Deposit = React.lazy(() => import('./views/banking/Deposit'))
const Transactions = React.lazy(() => import('./views/transactions/Transactions'))
const TransactionStatus = React.lazy(() => import('./views/transactions/TransactionFinalStatus'))

const Customers = React.lazy(() => import('./views/customers/Customers'))
const CustomerDetails = React.lazy(() => import('./views/customers/CustomerDetails'))

const Invoices = React.lazy(() => import('./views/invoices/Invoices'))
const InvoiceEdit = React.lazy(() => import('./views/invoices/InvoiceEdit'))
const InvoiceUploaded = React.lazy(() => import('./views/invoices/InvoiceUploaded'))
const InvoiceCheckout = React.lazy(() => import('./views/invoices/InvoiceCheckout'))
const EnotasInvoice = React.lazy(() => import('./views/enotas/EnotasInvoice'))

const SumSubVerification = React.lazy(() => import('./views/onboarding/SumSubVerification'))

const LazyReportDetailsPage = React.lazy(() => import('./views/expenseManagement/reportDetails'))

// prettier-ignore
export const publicRoutes = [
  { path: paths.login(), component: Login },
  { path: paths.signUpViaInviteLink(':referralCode'), check: paths.signUpViaInviteLink('*'), component: Signup },
  { path: paths.signUp(), component: Signup },
  { path: paths.invite(':link'), check: paths.invite('*'), component: React.lazy(() => import('./views/auth/Invite')) },
  { path: paths.waitListJoined(), exact: true, component: React.lazy(() => import('./views/auth/WaitListJoined')) },
  { path: paths.forgotPassword(), component: React.lazy(() => import('./views/auth/ForgotPassword')) },
  { path: paths.changePasswordEmail(), component: React.lazy(() => import('./views/auth/ForgotPasswordMessage')) },
  { path: paths.passwordReset(), component: React.lazy(() => import('./views/auth/ResetPasswordMessage')) },
  { path: paths.handlePasswordReset(), component: React.lazy(() => import('./views/auth/ResetPassword')) },
  { path: paths.checkEmail(), component: React.lazy(() => import('./views/auth/VerifyEmailMessage')) },
  { path: paths.handleEmailVerify(), component: React.lazy(() => import('./views/auth/VerifyEmail')) },
  { path: paths.handleInvitationVerify(), component: React.lazy(() => import('./views/auth/VerifyEmail')) },
  { path: paths.handlePasswordResetInvited(), component: React.lazy(() => import('./views/auth/SetPassword')) },

  { path: paths.invoices.checkout(':magicToken'), check: paths.invoices.checkout('*'), component: InvoiceCheckout },
  { path: paths.invoices.viewByToken(':magicToken'), check: paths.invoices.viewByToken('*'), component: InvoiceCheckout }
]

export const logoutAnd404Routes = [{ path: '*', component: React.lazy(() => import('./views/auth/Error404')) }]

// prettier-ignore
export const routesMain = [
  { path: paths.root(), exact: true, component: Outlet },
  { path: paths.settings(), component: Settings },
  { path: paths.dashboard(), component: Dashboard },
  { path: paths.yourForest(), component: React.lazy(() => import('./views/forest')) },
  { path: paths.userManagement(), component: React.lazy(() => import('./views/users')) },
  { path: paths.referralProgram(), component: React.lazy(() => import('./views/referral')) },
    ...(configuration.isProduction() ? [] : [{ path: paths.cards.list(), component: React.lazy(() => import('./views/card')) }]),

  { path: paths.banking.depositByAccountId(':accountId'), component: Deposit },
  { path: paths.banking.deposit(), component: Deposit },
  { path: paths.banking.send(), component: React.lazy(() => import('./views/banking/SendMoney')) },
  { path: paths.banking.exchange(), component: React.lazy(() => import('./views/banking/Exchange')) },
  { path: paths.banking.payWithlink(), component: React.lazy(() => import('./views/banking/PayWithLink')) },

  { path: paths.banking.transactions.details(':transactionId'), component: React.lazy(() => import('./views/transactions/TransactionDetails')) },
  { path: paths.recipients.details(':recipientId'), component: React.lazy(() => import('./views/recipients/RecipientDetails')) },
  { path: paths.recipients.list(), component: React.lazy(() => import('./views/recipients/Recipients')) },
  { path: paths.banking.accounts.list(), component: React.lazy(() => import('./views/account/Accounts')) },
  { path: paths.banking.transactions.listByAccountId(':accountId'), component: Transactions },
  { path: paths.banking.transactions.list(), component: Transactions },

  { path: paths.expenseManagement.expenseManagementDashboard(), component: ExpenseManagement },
  { path: paths.expenseManagement.counterParty(), component: ExpenseManagement },
  { path: paths.expenseManagement.reports(), component: ExpenseManagement },
  { path: paths.expenseManagement.singleCounterparty(':counterpartyId'), exact: true, component: React.lazy(() => import('./views/expenseManagement/counterpartyDetails')) },
  { path: paths.expenseManagement.report(':reportId'), exact: true, component: LazyReportDetailsPage },
  { path: paths.expenseManagement.payments(':reportId'), exact: true, component: React.lazy(() => import('./views/expenseManagement/reportPayments')) },
  { path: paths.expenseManagement.pay(), exact: true, component: React.lazy(() => import('./views/pay/PayPage')) },

  { path: paths.developer(), component: React.lazy(() => import('./views/developer')) },

  { path: paths.contactsInvoices(':payerId'), component: CustomerDetails },
  { path: paths.contacts(), component: Customers },
  { path: paths.customerInvoices(':payerId'), component: CustomerDetails },
  { path: paths.customers(), component: Customers },

  { path: paths.invoices.uploadEdit(':invoiceId'), component: InvoiceUploaded },
  { path: paths.invoices.upload(), component: InvoiceUploaded },
  { path: paths.invoices.addForCustomer(':customerId'), component: InvoiceEdit },
  { path: paths.invoices.add(), component: InvoiceEdit },
  { path: paths.invoices.send(':invoiceId'), component: React.lazy(() => import('./views/invoices/InvoiceSend')) },
  { path: paths.invoices.edit(':invoiceId'), component: InvoiceEdit },
  { path: paths.invoices.listByStatus(':status'), component: Invoices },
  { path: paths.invoices.list(), component: Invoices },
  { path: paths.enotas.invoice.createForCustomer(':customerId'), component: EnotasInvoice },
  { path: paths.enotas.invoice.create(), component: EnotasInvoice },

  { path: paths.onboarding.selectType(), exact: true, component: React.lazy(() => import('./views/onboarding/SumSubSelectType')) },
  { path: paths.onboarding.sumSub.start(':merchantType'), exact: true, component: SumSubVerification },
  { path: paths.onboarding.sumSub.continue(), exact: true, component: SumSubVerification },

  { path: paths.banking.boleto.fail(), exact: true, component: TransactionStatus, params: { isSuccess: false, method: 'boleto' } },
  { path: paths.banking.boleto.success(), exact: true, component: TransactionStatus, params: { isSuccess: true, method: 'boleto' } },
  { path: paths.banking.instantBankTransfer.fail(), exact: true, component: TransactionStatus, params: { isSuccess: false, method: 'direct_instant_bank_transfer' } },
  { path: paths.banking.instantBankTransfer.success(), exact: true, component: TransactionStatus, params: { isSuccess: true, method: 'direct_instant_bank_transfer' } },
  { path: paths.banking.finalStatus.fail(':method'), exact: true, component: TransactionStatus, params: { isSuccess: false } },
  { path: paths.banking.finalStatus.success(':method'), exact: true, component: TransactionStatus, params: { isSuccess: true } },
  { path: paths.ibanIssuingPreview(), component: React.lazy(() => import('./views/banking/IbanRequestPreview')) },
]

// prettier-ignore
export const submitterRoutes = [
  { path: paths.root(), exact: true, component: Outlet },
  { path: paths.dashboard(), redirect: paths.expenseManagement.expenseManagementDashboard() },
  { path: paths.expenseManagement.expenseManagementDashboard(), exact: true, component: ExpenseDashboard },
  { path: paths.expenseManagement.report(':reportId'), exact: true, component: LazyReportDetailsPage },
  { path: paths.settings(), component: Settings },
]

// prettier-ignore
export const approverRoutes = [
  { path: paths.root(), exact: true, component: Outlet },
  { path: paths.dashboard(), redirect: paths.expenseManagement.expenseManagementDashboard() },
  { path: paths.expenseManagement.expenseManagementDashboard(), exact: true, component: ExpenseDashboard },
  { path: paths.expenseManagement.report(':reportId'), exact: true, component: LazyReportDetailsPage },
  { path: paths.settings(), component: Settings },
]
