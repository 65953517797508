import { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import cn from 'classnames'

import { CurrencyBadge } from '../../../components'
import { SelectedAccount } from './SelectedAccount'
import { NoAvailableAccounts } from './NoAvailableAccounts'
import { Preloader, Button, ErrorDisplay } from 'mmfintech-portal-commons'
import {
  AccountsContainer,
  BadgeContainer,
  Buttons,
  Fees,
  FeeTitle,
  IbanRequestModalWrapper,
  Tab,
  TabsContainer
} from './IbanRequestModal.styled'

import { paths, useIbanRequestQry } from 'mmfintech-backend-api'
import { tr, GlobalContext, isValidArray, isValidString, formatMoney } from 'mmfintech-commons'

import { BankAccountScheme } from 'mmfintech-commons-types'

interface IbanRequestModalProps {
  reload?: () => void
}

export const IbanRequestModal = ({ reload }: IbanRequestModalProps) => {
  const { modalHide }: any = useContext(GlobalContext)

  const history = useHistory()

  const {
    selectedScheme,
    setSelectedScheme,
    selectedCurrency,
    setSelectedCurrency,
    selectedAccount,
    setSelectedAccount,
    availableSchemes,
    ibanSchemeCurrencies,
    ibanSchemeCurrenciesFetching,
    fees,
    accounts,
    accountsFetching,
    handleRequest,
    previewError,
    previewFetching,
    initiateError,
    initiateFetching,
    resetMutations
  } = useIbanRequestQry({
    onFee: payload => {
      modalHide()
      history.push({
        pathname: paths.ibanIssuingPreview(),
        state: { payload }
      })
    },
    onSuccess: () => {
      reload()
      modalHide()
      reset()
    }
  })

  const reset = () => resetMutations()

  const getFeeLabel = (type: string) => {
    switch (type) {
      case 'IBAN_ISSUING_FEE':
        return tr('FRONTEND.IBAN_ISSUING.FEES.IBAN_ISSUING_FEE', 'Issuing fee:')
      case 'MONTHLY_MAINTENANCE_FEE':
        return tr('FRONTEND.IBAN_ISSUING.FEES.MONTHLY_MAINTENANCE_FEE', 'Monthly maintenance fee:')
      default:
        return tr('FRONTEND.IBAN_ISSUING.FEES.OTHER', 'Other:')
    }
  }

  return (
    <IbanRequestModalWrapper>
      <div className='title'>{tr('FRONTEND.IBAN_ISSUING.MODAL.TITLE', 'IBAN Issuing Request')}</div>
      <div className='instructions-title'>
        {tr('FRONTEND.IBAN_ISSUING.MODAL.INSTRUCTIONS', 'Choose a payment network and currency for your IBAN:')}
      </div>
      <TabsContainer>
        {ibanSchemeCurrenciesFetching ? (
          <Preloader />
        ) : isValidArray(availableSchemes) ? (
          availableSchemes?.map(scheme => (
            <Tab
              key={`tab-${scheme}`}
              onClick={() => setSelectedScheme(scheme as BankAccountScheme)}
              className={cn({ active: selectedScheme === scheme })}
              data-test={`tab-${scheme.toLowerCase()}`}>
              {scheme}
            </Tab>
          ))
        ) : null}
      </TabsContainer>

      <BadgeContainer>
        {isValidString(selectedScheme) &&
          ibanSchemeCurrencies[selectedScheme]?.map((currency: string, index: number) => (
            <CurrencyBadge
              key={index}
              currencyCode={currency}
              isSelected={selectedCurrency === currency}
              onClick={() => setSelectedCurrency(currency)}
            />
          ))}
      </BadgeContainer>

      {isValidString(selectedScheme) && isValidString(selectedCurrency) && (
        <>
          <div className='associate-iban-title'>
            {tr('FRONTEND.IBAN_ISSUING.MODAL.ASSOCIATE.TITLE', 'Choose a currency account to associate with the IBAN:')}
          </div>
          <div className='subtitle'>
            {tr(
              'FRONTEND.IBAN_ISSUING.MODAL.ASSOCIATE.SUBTITLE',
              'If no selection is made, a new currency account will be created automatically.'
            )}
          </div>

          <AccountsContainer>
            {accountsFetching ? (
              <Preloader />
            ) : isValidArray(accounts) ? (
              <div className='inner'>
                {accounts?.map((account: any) => (
                  <SelectedAccount
                    account={account}
                    setAccount={account => {
                      if (account.id === selectedAccount?.id) {
                        setSelectedAccount(null)
                      } else {
                        setSelectedAccount(account)
                      }
                    }}
                    isSelected={selectedAccount?.id === account.id}
                    key={account.id}
                  />
                ))}
              </div>
            ) : (
              <NoAvailableAccounts currency={selectedCurrency} reload={reload} />
            )}
          </AccountsContainer>
        </>
      )}

      <div className='fees-container'>
        <div className='subtitle'></div>
        {isValidArray(fees) && isValidString(selectedCurrency) && (
          <Fees>
            {[...fees]
              .sort((a, b) => b.feeType.toLowerCase().localeCompare(a.feeType.toLowerCase()))
              .map((fee: any, index: number) => {
                const { feeType, feeCurrency, value } = fee
                return (
                  <FeeTitle key={index}>
                    <span>{getFeeLabel(feeType)}</span>
                    <span className='fee'>{formatMoney(value, feeCurrency)}</span>
                  </FeeTitle>
                )
              })}
          </Fees>
        )}
      </div>

      <ErrorDisplay error={[previewError, initiateError]} />

      <Buttons>
        <Button
          color='primary'
          text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
          disabled={previewFetching || initiateFetching}
          onClick={() => modalHide()}
          style={{ background: 'none' }}
          data-test='button-cancel'
        />

        <Button
          color='secondary'
          text={tr('FRONTEND.BUTTONS.REQUEST', 'Request')}
          onClick={handleRequest}
          data-test='button-new-account'
          loading={previewFetching || initiateFetching}
          disabled={!isValidString(selectedScheme) || !isValidString(selectedCurrency)}
        />
      </Buttons>
    </IbanRequestModalWrapper>
  )
}
