import React from 'react'
import { useHistory } from 'react-router-dom'

import { formatDateToUserLocale, formatMoney, isValidArray, tr } from 'mmfintech-commons'
import {
  isOwnerOrAdministrator,
  paths,
  selectCurrentUserId,
  selectCurrentUserRole,
  useAppSelector
} from 'mmfintech-backend-api'

import DeleteReportButton from './components/DeleteReportButton'

import { DataTable } from 'mmfintech-portal-commons'
import { NoReportsComponent } from './NoReportsComponent'
import { ReportStatusBadge } from '../expenseManagement/components'
import { ReportTableWrapper, SubmitterBadge } from './ReportsTable.styled'

import { ReportResponse, ReportStateEnum } from 'mmfintech-commons-types'

const actionStatuses: string[] = [ReportStateEnum.NEW, ReportStateEnum.EDIT]

type ReportTableProps = {
  data: ReportResponse[]
  isApproverTable?: boolean
  reloadPagination: () => void
}

export const ReportsTable: React.FC<ReportTableProps> = ({ data, isApproverTable, reloadPagination }) => {
  const history = useHistory()

  const userId = useAppSelector(selectCurrentUserId)
  const customerRole = useAppSelector(selectCurrentUserRole)

  const getTotalReportAmount = (report: ReportResponse) => {
    return formatMoney(report.totalAmount, report.currency)
  }

  const handleReportRowClick = (report: ReportResponse) => {
    if (
      // Check if the user is able to access payments page
      (report.state === ReportStateEnum.APPROVED ||
        report.state === ReportStateEnum.COMPLETELY_PAID ||
        report.state === ReportStateEnum.PARTIALLY_PAID ||
        report.state === ReportStateEnum.UNPAID) &&
      isOwnerOrAdministrator(customerRole)
    ) {
      history.push(paths.expenseManagement.payments(report?.id))
      return
    }

    history.push(paths.expenseManagement.report(report.id))
  }

  return (
    <ReportTableWrapper>
      <DataTable.Table>
        <DataTable.Head>
          <DataTable.Row className='headRow'>
            {isApproverTable && (
              <DataTable.HeadCell colSpan={2}>
                {tr('FRONTEND.REPORTS.COLUMN_SUBMITTER', 'Submitter')}
              </DataTable.HeadCell>
            )}
            <DataTable.HeadCell colSpan={2}>{tr('FRONTEND.REPORTS.COLUMN_NAME', 'Name')}</DataTable.HeadCell>
            <DataTable.HeadCell>{tr('FRONTEND.REPORTS.COLUMN_PERIOD', 'Period')}</DataTable.HeadCell>
            <DataTable.HeadCell>{tr('FRONTEND.REPORTS.COLUMN_DESCRIPTION', 'Description')}</DataTable.HeadCell>
            <DataTable.HeadCell>{tr('FRONTEND.REPORTS.COLUMN_EXPENSES', 'Expenses')}</DataTable.HeadCell>
            <DataTable.HeadCell>{tr('FRONTEND.REPORTS.COLUMN_STATUS', 'Status')}</DataTable.HeadCell>
            <DataTable.HeadCell className='text-right'>
              {tr('FRONTEND.REPORTS.COLUMN_TOTAL', 'Total')}
            </DataTable.HeadCell>
          </DataTable.Row>
        </DataTable.Head>

        {isValidArray(data) ? (
          <DataTable.Body className='main-tbody'>
            {data.map(report => {
              return (
                <DataTable.Row
                  key={report.id}
                  data-test={`open-report-${report.id}`}
                  onClick={() => handleReportRowClick(report)}>
                  {isApproverTable && (
                    <DataTable.Cell colSpan={2} caption={tr('FRONTEND.REPORTS.COLUMN_SUBMITTER', 'Submitter')}>
                      <SubmitterBadge>{report.submitter}</SubmitterBadge>
                    </DataTable.Cell>
                  )}
                  <DataTable.Cell
                    className='report-name'
                    caption={tr('FRONTEND.REPORTS.COLUMN_NAME', 'Name')}
                    colSpan={2}>
                    {report.name}
                  </DataTable.Cell>
                  <DataTable.Cell caption={tr('FRONTEND.REPORTS.COLUMN_PERIOD', 'Period')}>
                    {formatDateToUserLocale(report.fromDate)} - {formatDateToUserLocale(report.toDate)}
                  </DataTable.Cell>
                  <DataTable.Cell caption={tr('FRONTEND.REPORTS.COLUMN_DESCRIPTION', 'Description')}>
                    {report.description}
                  </DataTable.Cell>
                  <DataTable.Cell caption={tr('FRONTEND.REPORTS.COLUMN_EXPENSES', 'Expenses')}>
                    {report.expenses.length}
                  </DataTable.Cell>
                  <DataTable.Cell caption={tr('FRONTEND.REPORTS.COLUMN_STATUS', 'Status')}>
                    <ReportStatusBadge status={report.state} />
                  </DataTable.Cell>
                  <DataTable.Cell className='text-right' caption={tr('FRONTEND.REPORTS.COLUMN_TOTAL', 'Total')}>
                    {getTotalReportAmount(report)}
                  </DataTable.Cell>
                  <DataTable.Cell>
                    {actionStatuses.includes(report.state) && userId == report.userId && (
                      <DeleteReportButton data-test='delete-report-button' report={report} reload={reloadPagination} />
                    )}
                  </DataTable.Cell>
                </DataTable.Row>
              )
            })}
          </DataTable.Body>
        ) : (
          <NoReportsComponent title='No Reports' />
        )}
      </DataTable.Table>
    </ReportTableWrapper>
  )
}
