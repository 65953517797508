import { useContext, useState } from 'react'
import { Link } from 'react-router-dom'

import cn from 'classnames'

import DatePicker from 'react-datepicker'
import { TailSpin } from 'react-loader-spinner'
import { ErrorDisplay } from 'mmfintech-portal-commons'
import { ActivitiesContent, ActivitiesHeader, ActivitiesWrapper, ActivityWrapper } from './ActivitiesPane.styled'

import {
  tr,
  isValidArray,
  translateMonthName,
  compareDates,
  formatMoney,
  fixDateOnly,
  GlobalContext
} from 'mmfintech-commons'
import {
  paths,
  selectCurrentLanguage,
  transformMerchantActivity,
  useActivitiesQry,
  useAppSelector,
  useGetMerchantActivitiesQuery,
  useInvoicePreviewQry
} from 'mmfintech-backend-api'

import { ActivityTypeEnum } from 'mmfintech-commons-types'

import BackIcon from '../../../images/icons/date-back-arrow.svg?react'
import NextIcon from '../../../images/icons/date-next-arrow.svg?react'

import activityNoneIcon from '../../../images/icons/activity-none.svg'
import activityDepositIcon from '../../../images/icons/activity-deposit.svg'
import activityProfileIcon from '../../../images/icons/activity-profile.svg'
import activityTransferIcon from '../../../images/icons/activity-transfer.svg'
import activityWithdrawalIcon from '../../../images/icons/activity-withdrawal.svg'
import activityInvoicePaidIcon from '../../../images/icons/activity-invoice-paid.svg'
import activityInvoiceOpenedIcon from '../../../images/icons/activity-invoice-opened.svg'
import activityInvoiceCreatedIcon from '../../../images/icons/activity-invoice-created.svg'
import activityCustomerCreatedIcon from '../../../images/icons/activity-customer-created.svg'
import { InvoicePreview } from '../../invoices'

export const ActivitiesPane = () => {
  const { modalHide, modalShow } = useContext(GlobalContext)

  const [datum, setDatum] = useState(new Date())

  const selectedLanguage = useAppSelector(selectCurrentLanguage)

  const {
    data: activities,
    isFetching: activitiesFetching,
    error: activitiesError
  } = useGetMerchantActivitiesQuery({
    page: 0,
    size: 6,
    from: fixDateOnly(datum),
    to: fixDateOnly(datum)
  })

  const { fetchPreview, invoicePreviewFetching } = useInvoicePreviewQry()

  const { previewIndex, handleActivityClick } = useActivitiesQry({
    securityPath: paths.settings(),
    handleInvoicePreview: (invoiceId: number) => previewInvoice(invoiceId)
  })

  const previewInvoice = (invoiceId: number) => {
    void fetchPreview(invoiceId, data => {
      modalShow({
        options: {
          maxWidth: 820,
          size: 'large',
          closeIconPosition: 'speedy-special',
          onClose: modalHide
        },
        content: <InvoicePreview invoice={data} />
      })
    })
  }

  const getDateText = () => {
    const today = new Date()
    const dayOfMonth = datum.getDate()
    const month = translateMonthName(datum.getMonth())

    if (
      datum.getDate() === today.getDate() &&
      datum.getMonth() === today.getMonth() &&
      datum.getFullYear() === today.getFullYear()
    ) {
      const dayOfMonth = datum.getDate()
      const month = translateMonthName(datum.getMonth())

      return tr('FRONTEND.DASHBOARD.ACTIVITIES_TODAY', 'Today') + `, ${dayOfMonth} ${month.substring(0, 3)}.`
    }

    return `${dayOfMonth} ${month.substring(0, 3)}.`
  }

  const getActivityIcon = (type: string) => {
    switch (type) {
      case ActivityTypeEnum.ACCOUNT_APPROVED:
      case ActivityTypeEnum.REGISTERED_ACCOUNT:
        return activityProfileIcon

      case ActivityTypeEnum.CONTACT_CREATED:
      case ActivityTypeEnum.CUSTOMER_CREATED:
        return activityCustomerCreatedIcon

      case ActivityTypeEnum.ENOTAS_INVOICE_APPROVED:
      case ActivityTypeEnum.ENOTAS_INVOICE_CANCELED:
      case ActivityTypeEnum.ENOTAS_INVOICE_DECLINED:
      case ActivityTypeEnum.INVOICE_CREATED:
        return activityInvoiceCreatedIcon
      case ActivityTypeEnum.INVOICE_OPENED:
        return activityInvoiceOpenedIcon
      case ActivityTypeEnum.INVOICE_PAID:
      case ActivityTypeEnum.INVOICE_PARTIALLY_PAID:
        return activityInvoicePaidIcon

      case ActivityTypeEnum.TRANSFER_SENT:
      case ActivityTypeEnum.TRANSFER_RECEIVED:
      case ActivityTypeEnum.EXCHANGE_PROCESSED:
      case ActivityTypeEnum.REFUND_PENDING:
      case ActivityTypeEnum.REFUND_REJECTED:
      case ActivityTypeEnum.REFUND_PROCESSED:
      case ActivityTypeEnum.WALLET_PAYMENT_DECLINED:
      case ActivityTypeEnum.WALLET_PAYMENT_COMPLETED:
        return activityTransferIcon

      case ActivityTypeEnum.DEPOSIT_PENDING:
      case ActivityTypeEnum.DEPOSIT_PROCESSED:
      case ActivityTypeEnum.DEPOSIT_REJECTED:
      case ActivityTypeEnum.DEPOSIT_RETURNED:
        return activityDepositIcon

      case ActivityTypeEnum.WITHDRAWAL_PENDING:
      case ActivityTypeEnum.WITHDRAWAL_REJECTED:
      case ActivityTypeEnum.WITHDRAWAL_PROCESSED:
      case ActivityTypeEnum.WITHDRAWAL_REVERTED:
        return activityWithdrawalIcon

      default:
        return activityNoneIcon
    }
  }

  const handleDateBack = () => {
    const d = new Date(datum)
    d.setDate(d.getDate() - 1)
    setDatum(d)
  }

  const handleDateNext = () => {
    const d = new Date(datum)
    d.setDate(d.getDate() + 1)
    if (compareDates(d, new Date()) <= 0) {
      setDatum(d)
    }
  }

  return (
    <ActivitiesWrapper>
      <ActivitiesHeader>
        <div className='date-selection calendar-big'>
          <DatePicker
            locale={selectedLanguage}
            maxDate={new Date()}
            selected={datum}
            onChange={setDatum}
            calendarStartDay={1}
            customInput={<span className='selected-date'>{getDateText()}</span>}
          />

          <Link to='#' className='icon-button' onClick={handleDateBack}>
            <BackIcon />
          </Link>
          <Link to='#' className='icon-button' onClick={handleDateNext}>
            <NextIcon />
          </Link>
        </div>
        <div className='subtitle'>{tr('FRONTEND.DASHBOARD.ACTIVITIES_RECENT', 'Recent activities on this day')}</div>
      </ActivitiesHeader>

      <ActivitiesContent>
        {activitiesFetching ? (
          <div className='loader-spinner'>
            <TailSpin color='#828282' height={55} width={55} />
          </div>
        ) : activitiesError ? (
          <ErrorDisplay error={activitiesError} />
        ) : isValidArray(activities) ? (
          activities
            .filter((_v, index) => index < 6)
            .map((activity, index) => {
              const data = transformMerchantActivity(activity)

              return (
                <ActivityWrapper
                  key={index}
                  data-test='activity'
                  className={cn({ clickable: data.redirect?.length > 0 })}
                  onClick={() => handleActivityClick(data, index)}>
                  <div className='left'>
                    <div className='icon'>
                      {invoicePreviewFetching && index === previewIndex ? (
                        <TailSpin color='#828282' height={30} width={30} />
                      ) : (
                        <img src={getActivityIcon(data.type)} alt='' />
                      )}
                    </div>
                    <div className='description'>
                      {data.description}
                      {data.subtitle?.length ? <div>{data.subtitle}</div> : null}
                    </div>
                  </div>
                  <div className='amount'>{data.amount && formatMoney(data.amount.amount, data.amount.currency)}</div>
                </ActivityWrapper>
              )
            })
        ) : (
          <ActivityWrapper>
            <div className='icon'>
              <img src={activityNoneIcon} alt='' />
            </div>
            <div className='description'>{tr('FRONTEND.DASHBOARD.ACTIVITIES_NONE', 'No activities')}</div>
          </ActivityWrapper>
        )}
      </ActivitiesContent>
    </ActivitiesWrapper>
  )
}
