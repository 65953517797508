import styled from 'styled-components'
import { ExpenseModalHeader } from '../../reports/expenses/styles/expenseModal.styled'

export const ReportEditModalContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 4rem;
  justify-content: center;

  background: #1a1a1a;
  gap: 3rem;
  border-radius: 0.6rem;

  .input-wrapper {
    margin-bottom: 1.2rem;

    label {
      line-height: 120%;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  button {
    width: fit-content !important;
    margin: 0 auto;
  }

  .calendar-input > div {
    cursor: pointer;
    background: black;
    border: 0 solid black;
    .date-input {
      cursor: pointer;
      color: #afafaf;
    }

    &.error {
      border-color: #ff0431;
      border-width: 1px;
    }
  }
`

export const ReportEditModalHeader = styled(ExpenseModalHeader)``
